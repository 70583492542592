<!--未同步成功的交易-->
<template>
    <div class="DailySettle" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" v-model="form" size="small" label-width="92px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="交易单号">
                            <el-input v-model="form.tradeBar" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select placeholder="请选择" v-model="form.deptCode" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in depts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small">查询 </el-button>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <ef-review-biz ref="review" baseUrl="/finance/settle/daily" @completed="handleQuery" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                ref="table"
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="520"
            >
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="部门" width="160" prop="deptCodeName" />
                <el-table-column label="收银员" width="140" prop="cashierCodeName" />
                <el-table-column label="收银条码" width="140" prop="tradeBar" />
                <el-table-column label="实际支付" width="120">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.paid | money }}</span></template
                    >
                </el-table-column>
                <el-table-column label="交易时间" width="150" prop="tradeTime" />
                <el-table-column label="操作" header-align="center" prop="operate" key="operate">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="rowDetail(scope.row)">查看详情信息 </el-button>
                        <el-button size="small" type="danger" @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog title="详情" :visible.sync="dialogVisible" width="920">
            <el-table
                border
                ref="table"
                stripe
                style="width: 100%"
                :data="tableDtlData"
                size="mini"
                :highlight-current-row="true"
                height="520"
            >
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="商品名称" width="200" prop="skuCodeName" />
                <el-table-column label="数量" width="140" prop="count" />
                <el-table-column label="赠品数量" width="120" prop="giftCount" />
                <el-table-column label="总数量" width="200" prop="">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.count + scope.row.giftCount }}</span></template
                    >
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import Util from 'js/Util';
import EfReviewBiz from 'components/EfReviewBiz';
import { DeptTypeEnum } from 'js/DeptCommon';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
export default {
    name: 'QueryNoSyncRecord',
    mixins: [CheckTableShowColumn],
    components: { EfReviewBiz, CheckTableShowColumnDialog },
    data() {
        return {
            dialogVisible: false,
            form: {
                tradeBar: '',
                deptCode: '',
            },
            tableData: [],
            tableDtlData: [],
            url: {
                page: '/trade/cash/error/queryCashTradeErrorDo',
                dtlPage: '/trade/cash/error/queryCashTradeDetailErrorDo/',
                deleteUrl: '/trade/cash/error/deleteCashTradeDetailErrorDo',
            },
            auditCodes: [],
            audit: {
                auditDate: '',
                advice: '1',
                remark: '',
            },
            depts: [],
        };
    },
    mounted() {
        const _this = this;
        this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE).then((rst) => {
            _this.depts = rst;
            _this.handleQuery();
        });
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        rowDetail(row) {
            this.dialogVisible = true;
            const _this = this;
            const url = _this.url.dtlPage + row.code;
            Util.queryTable(
                _this,
                url,
                null,
                (data) => {
                    this.tableDtlData = data.data;
                },
                () => {}
            );
        },
        handleDelete(row) {
            const _this = this;
            const _params = { params: { deptCode: row.deptCode, tradeBar: row.tradeBar } };
            const url = _this.url.deleteUrl;
            Util.queryTable(
                _this,
                url,
                _params,
                (data) => {
                    _this.$message.success('交易已成功,交易失败记录已删除');
                    this.handleQuery();
                },
                (data) => {
                    _this.$message.error(data.data.message);
                }
            );
        },
    },
};
</script>
